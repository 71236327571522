<template>
  <!-- Error page-->
  <div class="error503">

    <!-- Banner -->
    <banner
      :back-image="bannerImage"
      :image="logo"
      :title="$t('error503.title')"
      :subtitle="$t('error503.subtitle')"
      height="600"
    />
  </div>
</template>

<script>

import Banner from 'Components/Common/Banner.vue'

export default {
  name: 'Error503',
  components: {
    Banner,
  },
  data() {
    return {
      bannerImage: '/static/limmat-images/errors/404.png',
      logo: '/static/limmat-images/logos/logoLimmat.png',
    }
  },
}
</script>
